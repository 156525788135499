<template>
  <div
      style="
      width: 100%;
      height: 60px;
      display: flex;
      justify-self: center;
      line-height: 60px;
    "
  >
    <el-menu

        class="el-menu-demo"
        :default-active="$route.path"
        mode="horizontal"
        background-color="#5EACF9"
        text-color="#ffffff"
        style="display: flex;width: 100%;justify-self: center;align-self: center;justify-content: center"
    >
      <!--      <img-->
      <!--          class="content-left"-->
      <!--          style="margin-top: 15px; float: left; margin-right: 40px"-->
      <!--          src="../assets/header_log.png"-->
      <!--          alt="LOGO"-->
      <!--      />-->
      <!-- <el-col style="color: black" :span="3" :offset="4"> -->

      <el-menu-item
          style="color: white"
          :class="isBlue('home')"
          @click="setActiveIndex('home')"
      >
        首页
      </el-menu-item>
      <!-- </el-col> -->

      <el-menu-item
          index="edu"
          :class="isBlue('edu')"
          @click="setActiveIndex('edu')"
      >
        教育动态
      </el-menu-item>

      <el-menu-item
          index="ques"
          :class="isBlue('ques')"
          @click="setActiveIndex('ques')"
      >
        试题中心
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('tall')"
          @click="setActiveIndex('tall')"
      >
        高考资源
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('VIP')"
          @click="setActiveIndex('VIP')"
      >
        VIP题库
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('hall')"
          @click="setActiveIndex('hall')"
      >
        高考走廊
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('teacher')"
          @click="setActiveIndex('teacher')"
      >
        师说心语
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('book')"
          @click="setActiveIndex('book')"
      >
        博览天下
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('school')"
          @click="setActiveIndex('school')"
      >
        走进名校
      </el-menu-item>

      <el-menu-item
          index=""
          :class="isBlue('member')"
          @click="setActiveIndex('member')"
      >
        会员中心
      </el-menu-item>
    </el-menu>

  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      activeIndex: "home",
    };
  },
  methods: {
    // handleMenuSelect(index) {
    //   this.$router.push(index)
    // },
    isBlueFun(val) {
      if (val === this.activeIndex) {
        return "textBlue";
      }
      return "";
    },
    setActiveIndex(val) {
      this.activeIndex = val;
      if (
          val === "hall" ||
          val === "edu" ||
          val === "book" ||
          val === "teacher"
      ) {
        this.$router.push("/front/edu/" + val);
        return;
      }
      if (val === "ques" || val === "tall" || val === "VIP") {
        this.$router.push("/front/topic/" + val);
        return;
      }
      this.$router.push("/front/" + val);
    },
    isBlue(val) {
      if (val === this.activeIndex) {
        return "bgBlue";
      }
      return "";
    },
  },
};
</script>
<style scoped lang="scss">
.bgBlue {
  //background-color: #5EACF9 !important;
  color: white !important;
  font-weight: bold;
}

.el-menu-item {
  font-size: 18px;
  padding: 1px 21px;
  height: 100%;

  font-family: PingFang SC-Regular, PingFang SC;
}
</style>
