<template style="width: 100%">
  <div class="box">
    <!--    头部 比如这个文件改动2222-->
    <div>
      <div class="center">
        <Header/>

      </div>


      <!--  导航栏  -->
      <div class="center" style="width: 1920px">
        <Navigation style="width: 100%"></Navigation>
      </div>

      <!-- 主体 -->
      <div class="center">

        <router-view/>
      </div>

      <!--    底部-->
      <div class="center">

        <Bottom/>

      </div>
    </div>
  </div>
</template>

<script>
import Header from './header.vue'
import Bottom from './bottom.vue'
import Navigation from "./navigation";

export default {
  name: 'Front',
  components: {
    Header,
    Bottom,
    Navigation
  },
  comments: {
    Header
  },
}
</script>

<style scoped>
.box {
  background: #F3FAFF;
  /*background: #000000;*/
  display: flex;
  justify-self: center;
  justify-content: center;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;

}
</style>
