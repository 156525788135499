<template>
  <div
      style="
        width: 1200px;
        height: 90px;
        line-height: 30px;
        display: flex;
      "
  >
    <!--    <span style="color: #0080FF;font-size: 16px">广州高考网 —</span>-->
    <!--    <span style="padding-left: 1%;color: #666666;font-size: 16px;">资源共享 轻松备考</span>-->

    <img
        class="content-left"
        height="90"
        style="float: left;"
        src="../assets/背景色1.png"
        alt="LOGO"
    />
    <img
        class="content-left"
        height="90"
        style="float: left;margin-left: 50px"
        src="../assets/背景色2.png"
        alt="LOGO"
    />

    <p class="login" style="color: #0080FF;float: right;margin-top: 30px" v-if="isLogin">
      欢迎您, {{ userInfo.sysMemberUser.name }}
      <span @click="loginOut" style="cursor: pointer;margin-left: 2px">
        退出
      </span>
    </p>
    <span style="float: right;margin-left: 150px;margin-top: 8px" v-else>
      <el-link class="login" @click="goLogin">登录/</el-link>
      <el-link class="login" @click="goRegister">注册</el-link>
      </span>
  </div>

</template>

<script>
import cookie from "js-cookie";
import {getUserInfo} from "../api/login";
import {updateVisitorNumber} from "../api/user";

export default {
  name: 'header',
  data() {
    return {
      isLogin: true,
      userInfo: {
        sysMemberUser: {
          name: ''
        }
      }
    }
  },
  created() {
    const token = cookie.get('access_token');
    if (!token) {
      this.isLogin = false;
      return;
    }
    this.getUserInfo();
  },
  methods: {
    loginOut() {
      updateVisitorNumber().then(res => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({path: "/"});
        });
      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data.data;
      })
    },
    goRegister() {
      this.$router.replace("/register")
    },
    goLogin() {
      this.$router.replace("/login")
    },
  }
}
</script>
<style lang="scss" scoped>
.login {
  font-size: 20px;
  top: 20px;
}
</style>
